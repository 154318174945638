/* (c) M iroslav Semora <semora@seznam.cz> 2020-2022, all rights reserved */
package com.dalineage.common.adt

import scala.collection.immutable._

import io.circe.parser.parse
import io.circe.Codec

object InputDefinitionDoc {
  def doc: String = """# Input Description JSON format #
"""
    + InputDefinitionADT.Doc.doc
}

object InputDefinitionADT {
  object Doc {

    def doc = minimal + options + analyzerOptions + outputOptions + ql

    val minimal = """
## Minimal configuration ##
```
{
  "name": "My first batch",
  "dialect": "tsql",
  "sources": [ { "name": "." } ]
}
```
"""
    val options = s"""
## Basic options ##
- name: String. Mandatory
- dialect: String, case insensitive. Mandatory.
-- Supported dialects are: TSQL (MS Sql Server), Oracle, Teradata, Impala, BigQuery
- sources: List of source specifications
```
  {
    "name": ...          # Dir or file entry inside uploaded ZIP containing source files.
                         # it can also be Database Model JSON file(s) *but not with .json suffix!*
                         # (only one file with .json suffix is allowed: Input Definition JSON)
    "defaultSchema": ... # optional
    "defaultDatabase":   # optional
  }
```
"""
  }

  case class InputDefinitionJson(
      name: String,
      dialect: String,
      sourceFiles: Option[List[String]],
      sourceFile: Option[String],
      sources: Option[List[SourceSpec]],
      replace: Option[Boolean] = None,
      description: Option[String] = None,
      showColumnTypes: Option[Boolean] = None,
      defaultDatabase: Option[String] = None,
      defaultSchema: Option[String] = None,
      output: Option[InputDefinitionOutput] = Some(InputDefinitionOutput()),
      authorization: Option[InputDefinitionAuthorization] = Some(InputDefinitionAuthorization()),
      showQueryLineage: Option[Boolean] = None,
      showDatabaseImage: Option[Boolean] = None,
      queryLineageOptions: Option[InputDefinitionQueryLineage] = None,
      databaseImageOptions: Option[InputDefinitionDatabaseImage] = None,
      analyzerOptions: Option[InputDefinitionAnalyzer] = None,
      maxPasses: Option[Int] = None,
      dir: Option[String] = None,
      tsqlOptions: Option[TSQLOptions] = None
  ) derives Codec.AsObject

  case class ExtraRelation(
    fileName: String,
    source: (String, String, String, String, String),
    target: (String, String, String, String, String),
    comment: Option[String] = None,
  ) derives Codec.AsObject

  case class InputDefinitionQLConstants(
      groupConstants: Option[String] = None
  ) derives Codec.AsObject

  case class SourceSpec(
    name: String,
    defaultDatabase: Option[String],
    defaultSchema: Option[String],
    isExportedDbModel: Option[Boolean],
    exclude: Option[List[String]] = None,
  ) derives Codec.AsObject

  val ql = """
## Query lineage options ##
```
"queryLineageOptions": { ... }
```
- expandTables: Boolean. Default: false. Expand tables on diagram, otherwise only header is shown
- expandGroups: Boolean. Default: false. Expand groups on diagram, otherwise only header is shown
- groupExpandLevel: Int. Default: 0. Expand groups on diagram to this level. 0 means no limit
- showIndirectRelations: Boolean. Default: false.
  Show relations originated in WHERE/GROUP BY/HAVING/ORDER BY
"""

  case class InputDefinitionQueryLineage(
      expandTables: Option[Boolean] = None,
      expandGroups: Option[Boolean] = None,
      groupExpandLevel: Option[Int] = None,
      constants: Option[InputDefinitionQLConstants] = None,
      groupCodeBlocks: Option[Boolean] = None,
      groupProcedures: Option[Boolean] = None,
      showColumnStruct: Option[String] = None,
      showUnresolvedVariants: Option[Boolean] = None,
      showExpressions: Option[String] = None,
      showVariables: Option[Boolean] = None,
      showProcedureLineage: Option[Boolean] = None,
      showUnusedTableReferences: Option[Boolean] = None
  ) derives Codec.AsObject

  val analyzerOptions = """
## Analyzer options ###
```
{
  "analyzerOptions": { ... }
}
```
- `autoCreate`: Boolean. Default: false. Automatically create missing tables and views
- `multiPass`: Boolean. Default: false. Run analyzer in multiple passes to resolve more missing tables and views
- `skipDatatypeErrors`: Boolean. Default: false. Use "Unknown" datatype when datatype analysis fails
- `showQueryLineage`: Boolean. Default: false. Saves detailed query lineage to diagram.
  - see `queryLineageOptions` for related options
"""

  val clientApplicaton = "client application"
  val outputOptions = s"""
## Output options ##
- `saveObjectBrowserData`: Boolean. Default: false.
  - Save database image and source code list for Object Browser. Object Browser is a part of $clientApplicaton enabling tree view and search of source code and database objects.
  - Tip: Activate object browser on $clientApplicaton by pressing *b*. Search by pressing *s*.
"""

  case class InputDefinitionAnalyzer(
    autoCreate: Option[Boolean] = None,
    multiPass: Option[Boolean] = None,
    strict: Option[Boolean] = None,
    skipStrictErrors: Option[Boolean] = None, //obsolete
    skipUnresolvedHostVariables: Option[Boolean] = None,
    skipParsingErrors: Option[Boolean] = None,
    skipAnalyzerErrors: Option[Boolean] = None,
    skipDatatypeErrors: Option[Boolean] = None,
    showSemidirectRelations: Option[Boolean] = None, //process indirect before FROM as direct
    extraRelations: Option[List[ExtraRelation]] = None,
    showIndirectRelations: Option[Boolean] = None,
  ) derives Codec.AsObject

  case class InputDefinitionDatabaseImage(
      expandTables: Option[Boolean] = None,
      expandGroups: Option[Boolean] = None,
      groupExpandLevel: Option[Int] = None,
      showColumnLineage: Option[Boolean] = None,
      groupTables: Option[String] = None,
      showTableVersions: Option[String] = None,
  ) derives Codec.AsObject

  case class InputDefinitionOutput(
    generateGoJsOutput: Option[Boolean] = None,       //for server default true, for test false
    generateGoJsView: Option[Boolean] = None,
    generateDawisoOutput: Option[Boolean] = None,
    saveObjectBrowserData: Option[Boolean] = None,
    saveSourceQueries: Option[Boolean] = None,
    saveBatchInfo: Option[Boolean] = None,            //for server always saved
    /* Directory name. Use this opion to inspect C2C lineage -
     * - each relation will be dumped with its detailed query lineage path.
     * set showQueryLineage to true when this option is set*/
    traceColumnLineage: Option[String] = None,
    dumpGraph: Option[String] = None,                 //dir name to save graph dump
    dumpLineage: Option[String] = None,               //dir name to save lineage dump
    dumpParsingErrors: Option[String] = None,
    dumpAnalyzerErrors: Option[String] = None,
    dumpEvalTree: Option[String] = None,
    dumpInitialEvalTree: Option[String] = None,
    dumpAstDir: Option[String] = None,
  ) derives Codec.AsObject

  case class InputDefinitionAuthorization(
    authUsers: List[String] = Nil
  ) derives Codec.AsObject

  case class TSQLOptions(
      scriptingVariables: Map[String, String] = Map.empty
  ) derives Codec.AsObject
}
