/* (c) Dalineage, s.r.o. 2020-2024, all rights reserved */
package com.dalineage.client

import scala.util.chaining._
import scala.util.{Success, Failure}
import scala.scalajs.js
import js.JSConverters._
import org.scalactic.TripleEquals.convertToEqualizer

import scala.concurrent.{Promise, Future}
import scala.concurrent.ExecutionContext.Implicits.global
import cats.syntax.all._
import typings.gojs.{mod => go}

import com.dalineage.client

trait IDBDatabaseUtils {
  import IDBDatabaseADT._
  import IDBDatabaseTS._

  import org.scalajs.dom
  import dom.{ Event, ErrorEvent }
  import dom.{ IDBDatabase, IDBObjectStore }

  val dbName: String
  val version: Int = 1
  val verbose: Boolean
  val defaultKeyPath: String = "key"

  protected def logEvent(logger: Function2[js.Any, Seq[js.Any], Unit])(event: Event): Unit =
    if (verbose) logger(event, Seq.empty[js.Any])

  protected def getErrorMsg(error: ErrorEventTS): String =
    s"name: ${error.name}; code: ${error.code}; message: ${error.message}"

  protected def getErrorMsg(event: ErrorEvent): String =
    event
      .pipe(getEventError)
      .pipe(getErrorMsg)

  protected def getEventError(event: ErrorEvent): ErrorEventTS =
    event.target.asInstanceOf[js.Dynamic]
      .selectDynamic("error").asInstanceOf[js.UndefOr[ErrorEventTS]].toOption
      .recoverWith(_ => Option(ErrorEventTS(event.message)))
      .getOrElse(ErrorEventTS("Unknown error"))

  /*   possible error codes:
  * ABORT_ERR: 20
  * DATA_CLONE_ERR: 25
  * DOMSTRING_SIZE_ERR: 2
  * HIERARCHY_REQUEST_ERR: 3
  * INDEX_SIZE_ERR: 1
  * INUSE_ATTRIBUTE_ERR: 10
  * INVALID_ACCESS_ERR: 15
  * INVALID_CHARACTER_ERR: 5
  * INVALID_MODIFICATION_ERR: 13
  * INVALID_NODE_TYPE_ERR: 24
  * INVALID_STATE_ERR: 11
  * NAMESPACE_ERR: 14
  * NETWORK_ERR: 19
  * NOT_FOUND_ERR: 8
  * NOT_SUPPORTED_ERR: 9
  * NO_DATA_ALLOWED_ERR: 6
  * NO_MODIFICATION_ALLOWED_ERR: 7
  * QUOTA_EXCEEDED_ERR: 22
  * SECURITY_ERR: 18
  * SYNTAX_ERR: 12
  * TIMEOUT_ERR: 23
  * TYPE_MISMATCH_ERR: 17
  * URL_MISMATCH_ERR: 21
  * VALIDATION_ERR: 16
  * WRONG_DOCUMENT_ERR: 4
  */
  private def isConstraintError(event: ErrorEvent): Boolean =
    event
      .pipe(getEventError)
      .pipe(isConstraintError)

  protected def isConstraintError(error: ErrorEventTS): Boolean =
    error.code == 0 && error.name == "ConstraintError"

  protected def createStore(storeName: GoJSStoreName, keyPath: String = defaultKeyPath, autoIncrement: Boolean = false)(idb: IDBDatabase): IDBObjectStore =
    idb
      .tap{
        case db: IDBDatabase if db.objectStoreNames.nonEmpty && db.objectStoreNames.contains(storeName) => db.deleteObjectStore(storeName)
        case _: IDBDatabase => ()
      }
      .pipe(_.createObjectStore(storeName, IDBCreateObjectStoreOptions(keyPath, autoIncrement)))

  protected def createIndex(idxName: String, keyPath: String, unique: Boolean = false)(store: IDBObjectStore): IDBObjectStore =
    store
      .tap(_.createIndex(idxName, keyPath, IDBCreateIndexOptions(unique)))
}

trait IDBDatabaseRequests extends IDBDatabaseUtils {
  import IDBDatabaseADT._
  import IDBDatabaseTS._

  import org.scalajs.dom

  import dom.console
  import dom.window.indexedDB
  import dom.{ Event, IDBEvent, ErrorEvent }
  import dom.{
    IDBDatabase,
    IDBVersionChangeEvent,
    IDBObjectStore,
    IDBTransactionMode,
    IDBKey,
    IDBValue,
    IDBCursorWithValue,
    IDBKeyRange,
    IDBIndex
  }

  import client.LineageTS._

  def openDBRequest(): Future[IDBDatabase] = {

    val promise = Promise[IDBDatabase]()

    indexedDB
      .toOption
      .fold
        (promise.failure(new Exception("IndexedDB not supported")))
        (
          _.open(dbName, version)
            .tap(_.onsuccess = (event: IDBEvent[IDBDatabase]) =>
              event
                .pipe(_.target.result)
                .tap((idb: IDBDatabase) => console.log(s"Database $dbName opened with version ${idb.version}"))
                .pipe(promise.success)
            )
            .tap(_.onerror = (event: ErrorEvent) =>
              event
                .tap(logEvent(console.error))
                .pipe(getErrorMsg(_))
                .pipe(msg => s"Failed to open database: $msg")
                .tap(console.error(_))
                .pipe(msg => promise.failure(new Exception(msg)))
            )
            .tap(_.onupgradeneeded = (event: IDBVersionChangeEvent) => {
              console.log(s"Upgrading database $dbName from version ${event.oldVersion} to version ${event.newVersion}")
              event.target.result
                .pipe(createStore(GoJSStoreName.nodes))
                .pipe(createIndex("groupIDX", "group"))

              event.target.result
                .pipe(createStore(GoJSStoreName.links, autoIncrement =  true))
                .pipe(createIndex("fromIDX", "from"))
                .pipe(createIndex("toIDX", "to"))
            })
            .tap(_.onblocked = (event: IDBVersionChangeEvent) =>
              event
                .tap(logEvent(console.error))
                .pipe(_ => s"Database $dbName is blocked!")
                .tap(console.error(_))
            )
            .tap(_.addEventListener("close", (_: Event) => console.log(s"Database $dbName closed.")))
        )

    promise.future
  }

  def clearStoreRequest(store: IDBObjectStore): Future[IDBObjectStore] = {
    val promise = Promise[IDBObjectStore]()

    store
      .tap(
        _.clear()
        .tap(_.onsuccess = (event: IDBEvent[Unit]) => {
          event.target.result
            .tap(_ => console.log(s"Store ${store.name} cleared."))
            .pipe(_ => promise.success(store))
        })
        .tap(_.onerror = (event: ErrorEvent) =>
          event
            .tap(logEvent(console.error))
            .pipe(getErrorMsg(_))
            .pipe(msg => s"Failed to clear store: $msg")
            .tap(console.error(_))
            .pipe(new Exception(_))
            .pipe(promise.failure)
        )
      )

      promise.future
  }

  def addBatchRequest(values: js.Array[go.ObjectData], ignoreConstraintError: Boolean = false)(store: IDBObjectStore): Future[Int] =
    values
      .toList
      .map(addRequest(_, ignoreConstraintError)(store))
      .pipe(Future.sequence(_))
      .map(_.partition{
        case Right(_: IDBKey) => true
        case Left(_: ErrorEventTS) => false
      })
      .flatMap{ case (success, errors) =>
        errors.foldLeft(Nil)((acc: List[String], error) => error match
          case Left(err) if ignoreConstraintError && isConstraintError(err) =>
            console.warn(s"Failed to add value into ${store.name} - ${getErrorMsg(err)}")
            acc
          case Left(err)  => getErrorMsg(err) :: acc
          case _ => acc
        ) match
          case Nil => Future.successful(success.size)
          case messages => Future.failed(new Exception(s"Failed to add values into ${store.name}:\n${messages.mkString("\n")}"))
      }

  def addRequest(value: go.ObjectData, ignoreConstraintError: Boolean)(store: IDBObjectStore): Future[Either[ErrorEventTS, IDBKey]] = {
    val promise = Promise[Either[ErrorEventTS, IDBKey]]()
    store
      .add(value)
      .tap(_.onsuccess = (event: IDBEvent[IDBKey]) => event
        .pipe(_.target.result)
        .tap(result => if (verbose) console.log(s"Value added into ${store.name} with key: $result"))
        .pipe(result => promise.success(result.asRight[ErrorEventTS]))
      )
      .tap(_.onerror = (event: ErrorEvent) => event
        .tap(logEvent(console.error))
        .tap(_.stopPropagation()) // stop error from bubbling up to the transaction.onerror handler
        .tap(_.preventDefault())  // cancel the error event - do not abort the transaction
        .pipe(getEventError)
        .pipe(_.asLeft[IDBKey])
        .pipe(promise.success)
      )

    promise.future
  }

  def updateRequest(value: go.ObjectData)(store: IDBObjectStore): Future[IDBKey] = {
    val promise = Promise[IDBKey]()

    store
      .put(value)
      .tap(_.onsuccess = (event: IDBEvent[IDBKey]) => event
        .pipe(_.target.result)
        .tap(result => if (verbose) console.log(s"Value updated in ${store.name} with key: $result"))
        .pipe(promise.success)
      )
      .tap(_.onerror = (event: ErrorEvent) => event
        .tap(logEvent(console.error))
        .pipe(getErrorMsg(_))
        .pipe(msg => s"Failed to update value in ${store.name}: $msg")
        .tap(console.error(_))
        .pipe(new Exception(_))
        .pipe(promise.failure)
      )

    promise.future
  }

  def getAllRequest(store: IDBObjectStore): Future[js.Array[IDBValue]] = {
    val promise = Promise[js.Array[IDBValue]]()

    store
      .getAll()
      .tap(_.onsuccess = (event: IDBEvent[js.Array[IDBValue]]) => event
        .pipe(_.target.result)
        .tap(result => if (verbose) console.log(s"Get ${result.length} values from ${store.name}"))
        .pipe(promise.success)
      )
      .tap(_.onerror = (event: ErrorEvent) => event
        .tap(logEvent(console.error))
        .pipe(getErrorMsg(_))
        .pipe(msg => s"Failed to get all values from ${store.name}: $msg")
        .tap(console.error(_))
        .pipe(new Exception(_))
        .pipe(promise.failure)
      )

    promise.future
  }

  def getByPrimaryKeyRequest(key: IDBKey)(store: IDBObjectStore): Future[IDBValue] = {
    val promise = Promise[IDBValue]()

    store
      .get(key)
      .tap(_.onsuccess = (event: IDBEvent[IDBValue]) => event
        .pipe(_.target.result)
        .tap((value: IDBValue) => if (verbose) console.info(s"Get value from ${store.name}: ${value.asInstanceOf[LineageItem].toJsonString}"))
        .pipe(promise.success)
      )
      .tap(_.onerror = (event: ErrorEvent) => event
        .tap(logEvent(console.error))
        .pipe(getErrorMsg(_))
        .pipe(msg => s"Failed to get value with key $key from ${store.name}: $msg")
        .tap(console.error(_))
        .pipe(new Exception(_))
        .pipe(promise.failure)
      )

    promise.future
      .ensure
        (new NoSuchElementException(s"Node with key $key not found in ${store.name}!"))
        (node => !js.isUndefined(node))
  }

  def deleteRequest(key: IDBKey)(store: IDBObjectStore): Future[Boolean] = {
    val promise = Promise[Boolean]()

    store
      .delete(key)
      .tap(_.onsuccess = (event: IDBEvent[Unit]) =>
        if (verbose) console.info(s"Deleted value from ${store.name}: $key")
        promise.success(true)
      )
      .tap(_.onerror = (event: ErrorEvent) => event
        .tap(logEvent(console.error))
        .pipe(getErrorMsg(_))
        .pipe(msg => s"Failed to delete value with key $key from ${store.name}: $msg")
        .tap(console.error(_))
        .pipe(new Exception(_))
        .pipe(promise.failure)
      )

    promise.future
  }

  def getPrimaryKeysWithFilterRequest(filter: IDBValue => Boolean, optIdxName: Option[String] = None, optRange: Option[IDBKeyRange | IDBKey] = None)(store: IDBObjectStore): Future[js.Array[IDBKey]] = {
    val promise = Promise[js.Array[IDBKey]]()
    var result: js.Array[IDBKey] = js.Array[IDBKey]()

    (store.name == GoJSStoreName.nodes)
      .ensuring(_ == true)
      .pipe(scala.util.Try(_)) match
        case Failure(_) =>
          s"Store ${store.name} is not a valid store for this operation!"
            .pipe(Exception(_))
            .pipe(promise.failure)
        case Success(value) => store
          .pipe(store =>
            optIdxName.fold
              (store)
              (store.index(_))
          )
          .pipe(_.openCursor(optRange.orUndefined))
          .tap(_.onsuccess = (event: IDBEvent[IDBCursorWithValue[IDBObjectStore | IDBIndex]]) => event
            .pipe(_.target.result)
            .pipe(Option(_))
            .tap{
              case None => promise.success(result)
              case Some(cursor) => cursor.tap(
                _.value.asInstanceOf[IDBValue]
                .tap{
                  case value: IDBValue if filter(value) =>
                    if (verbose) console.info(s"Get value from ${store.name}: ${value.asInstanceOf[LineageItem].toJsonString}")
                    result.addOne(cursor.primaryKey)
                  case _ => ()
                }
              ).continue()
            }
          )
          .tap(_.onerror = (event: ErrorEvent) => event
            .tap(logEvent(console.error))
            .pipe(getErrorMsg(_))
            .pipe(msg => s"Failed to GET ALL KEY values with filter: $msg")
            .tap(console.error(_))
            .pipe(new Exception(_))
            .pipe(promise.failure)
          )

    promise.future
  }

  def getWithFilterRequest(filter: Option[IDBValue => Boolean] = None, optIdxName: Option[String] = None, optRange: Option[IDBKeyRange | IDBKey] = None)(store: IDBObjectStore): Future[js.Array[IDBValue]] = {
    val promise = Promise[js.Array[IDBKey]]()
    var result: js.Array[IDBKey] = js.Array[IDBKey]()

    def addToResult(value: IDBValue): Unit = {
      if (verbose) console.info(s"Get value from ${store.name}: ${value.asInstanceOf[LineageItem].toJsonString}")
      result.addOne(value)
    }

    store
      .pipe(store =>
        optIdxName.fold
          (store)
          (store.index(_))
      )
      .pipe(_.openCursor(optRange.orUndefined))
      .tap(_.onsuccess = (event: IDBEvent[IDBCursorWithValue[IDBObjectStore | IDBIndex]]) => event
        .pipe(_.target.result)
        .pipe(Option(_))
        .tap{
          case None => promise.success(result)
          case Some(cursor) => cursor.tap(
            _.value
            .tap((value: IDBValue) =>
              filter.fold
                (addToResult(value))
                (filterFn => filterFn(value) match
                  case true => addToResult(value)
                  case false => ()
                )
            )
          ).continue()
        }
      )
      .tap(_.onerror = (event: ErrorEvent) => event
        .tap(logEvent(console.error))
        .pipe(getErrorMsg(_))
        .pipe(msg => s"Failed to GET ALL KEY values with filter: $msg")
        .tap(console.error(_))
        .pipe(new Exception(_))
        .pipe(promise.failure)
      )

    promise.future
  }

  def setLinkToVisibleNode(link: LineageLink, visibleFrom: Option[LineageNode] = None, visibleTo: Option[LineageNode] = None)(nodesStore: IDBObjectStore, linksStore: IDBObjectStore): Future[LineageLink] =
    (for {
      from <- visibleFrom.fold
        (getByPrimaryKeyRequest(link.from)(nodesStore))
        (Future.successful)
      to <- visibleTo.fold
        (getByPrimaryKeyRequest(link.to)(nodesStore))
        (Future.successful)
    } yield (from, to))
    .map((fromNode: IDBValue, toNode: IDBValue) => (
      fromNode.asInstanceOf[LineageNode],
      toNode.asInstanceOf[LineageNode])
    )
    .flatMap((fromNode: LineageNode, toNode: LineageNode) =>
      (fromNode.visible && toNode.visible) match
        case true =>
          (fromNode.isTable && fromNode.expanded || toNode.isTable && toNode.expanded) match
            case true => Future.successful(link)
            case false => Future.successful(link.setFromPort("").setToPort(""))
        case false =>
          if (!fromNode.visible) link.setFrom(fromNode.group.get).setFromPort("")
          if (!toNode.visible) link.setTo(toNode.group.get).setToPort("")
          setLinkToVisibleNode(
            link,
            fromNode.visible match
              case true => Some(fromNode)
              case false => None,
            toNode.visible match
              case true => Some(toNode)
              case false => None
            )(nodesStore, linksStore)
    )
    .andThen{
      case Failure(exception) => exception match
        case e: NoSuchElementException if linksStore.transaction.mode == IDBTransactionMode.readwrite =>
          deleteRequest(link.key)(linksStore)
        case e =>
          Future.failed(e)
    }

  def getLinksRequest(optIdxName: Option[String] = None, optRange: Option[IDBKeyRange] = None)(linksStore: IDBObjectStore, nodesStore: IDBObjectStore): Future[js.Array[LineageLink]] = {
    val promise = Promise[js.Array[LineageLink]]()
    val result: LineageLinkSet = LineageLinkSet()

    linksStore
      .pipe(store =>
        optIdxName.fold
          (store)
          (store.index(_))
      )
      .pipe(_.openCursor(optRange.orUndefined))
      .tap(_.onsuccess = (event: IDBEvent[IDBCursorWithValue[IDBObjectStore | IDBIndex]]) => event
          .pipe(_.target.result)
          .pipe(Option(_))
          .tap{
            case None => promise.success(result.unwrap)
            case Some(cursor) =>
              cursor.value.asInstanceOf[LineageLink]
                .pipe(setLinkToVisibleNode(_))
                .pipe(_(nodesStore, linksStore))
                .map(_.tap(link => if (verbose) console.info(s"Get value from ${linksStore.name}: ${link.toJsonString}")))
                .map(_.tap(link => result.addUniqueItem(link)))
                .onComplete{
                  case Success(_) => cursor.continue()
                  case Failure(exception) =>
                    console.error(s"Failed to get link from ${linksStore.name}: ${exception.getMessage}")
                    cursor.continue()
                }
          }
      )
      .tap(_.onerror = (event: ErrorEvent) =>
        event
          .tap(logEvent(console.error))
          .pipe(getErrorMsg(_))
          .pipe(msg => s"Failed to get all values with filter: $msg")
          .tap(console.error(_))
          .pipe(new Exception(_))
          .pipe(promise.failure)
      )

    promise.future
  }
}

case class IDBDatabaseService(dbName: String = "GOJS_Lineage", verbose: Boolean = false)
  extends IDBDatabaseRequests {

  import IDBDatabaseADT._
  import LineageTS._

  import org.scalajs.dom
  import dom.console
  import dom.{ Event, ErrorEvent }
  import dom.{
    IDBDatabase,
    IDBTransactionMode,
    IDBTransaction,
    IDBObjectStore,
    IDBEvent,
    IDBKey,
    IDBKeyRange,
    IDBValue
  }

  def selectNode(optTx: Option[IDBTransaction] = None)(nodeKey: String): Future[IDBKey] = {
    def auxFn(key: IDBKey)(transaction: IDBTransaction): Future[Boolean] =
      getNodeByKey(Some(transaction))(key.asInstanceOf[String])
        .flatTap(expandNode(Some(transaction))(_, true))
        .map(_.asInstanceOf[LineageNode])
        .flatMap(_.group.fold
          (Future.successful(true))
          (key => auxFn(key.asInstanceOf[IDBKey])(transaction))
        )

    def call(transaction: IDBTransaction): Future[Boolean] =
      getNodeByKey(Some(transaction))(nodeKey)
        .map(_.asInstanceOf[LineageNode])
        .flatMap(_.group.fold
          (Future.successful(true))
          (key => auxFn(key.asInstanceOf[IDBKey])(transaction))
        )

    optTx match
      case Some(transaction) =>
        call(transaction)
          .as[IDBKey](nodeKey)
      case None =>
        openDBRequest()
          .map(_.transaction(GoJSStoreName.nodes, IDBTransactionMode.readwrite))
          .flatMap((transaction: IDBTransaction) =>
            val promise = Promise[IDBKey]()

            transaction
              .tap(_.onerror = (event: ErrorEvent) =>
                event
                  .tap(logEvent(console.error))
                  .pipe(getErrorMsg(_))
                  .pipe(msg => s"Transaction SELECT NODE failed with message: $msg")
                  .tap(console.error(_))
              )
              .tap(_.onabort = (event: Event) =>
                event
                  .tap(logEvent(console.warn))
                  .tap(_ => console.warn(s"Transaction SELECT NODE from $dbName aborted!"))
                  .pipe(e => promise.failure(new Exception(s"Transaction SELECT NODE aborted! ${e.toString()}")))
              )
              .tap(_.oncomplete = (event: Event) =>
                nodeKey.asInstanceOf[IDBKey]
                .tap(
                  _.pipe(n => s"Transaction SELECT NODE from $dbName completed!")
                  .pipe(console.info(_))
                )
                .pipe(promise.success)
              )
              .pipe(call)
              .onComplete({
                case Success(_) => ()
                case Failure(exception) =>
                  console.error(s"Failed to select node with key ${nodeKey.asInstanceOf[LineageNode].key} from $dbName: ${exception.getMessage}")
                  transaction.abort()
              })

              promise.future
          )
  }

  def getLinksByTable(optTx: Option[IDBTransaction] = None)(table: go.ObjectData): Future[js.Map[String, js.Array[LineageLink]]] = {
    def call(transaction: IDBTransaction): Future[js.Map[String, js.Array[LineageLink]]] =
      for {
        fromLinks <- getLinksRequest
          (
            optIdxName = Some("fromIDX"),
            optRange = Some(IDBKeyRange.only(table.asInstanceOf[LineageNode].key))
          )
          (
            transaction.objectStore(GoJSStoreName.links),
            transaction.objectStore(GoJSStoreName.nodes)
          )

        toLinks <- getLinksRequest
          (
            optIdxName = Some("toIDX"),
            optRange = Some(IDBKeyRange.only(table.asInstanceOf[LineageNode].key))
          )
          (
            transaction.objectStore(GoJSStoreName.links),
            transaction.objectStore(GoJSStoreName.nodes)
          )
      } yield js.Map(
        "fromLinks" -> fromLinks,
        "toLinks" -> toLinks
      )

    optTx match
      case Some(transaction) =>
        call(transaction)
      case None =>
        openDBRequest()
          .map(_.transaction(js.Array(GoJSStoreName.nodes, GoJSStoreName.links), IDBTransactionMode.readonly))
          .flatMap((transaction: IDBTransaction) => {
            val promise = Promise[js.Map[String, js.Array[LineageLink]]]()
            val result: js.Map[String, js.Array[LineageLink]] = js.Map.empty[String, js.Array[LineageLink]]

            transaction
            .tap(_.onerror = (event: ErrorEvent) =>
              event
                .tap(logEvent(console.error))
                .pipe(getErrorMsg(_))
                .pipe(msg => s"Transaction GET LINKS BY TABLE failed with message: $msg")
                .tap(console.error(_))
            )
            .tap(_.onabort = (event: Event) =>
              event
                .tap(logEvent(console.warn))
                .tap(_ => console.warn(s"Transaction GET TABLE BY NODE from $dbName aborted!"))
                .pipe(e => promise.failure(new Exception(s"Transaction GET LINKS BY TABLE aborted! ${e.toString()}")))
            )
            .tap(_.oncomplete = (event: Event) =>
              result
              .tap(_
                .pipe(res => for {
                  from <- res.get("fromLinks")
                  to <- res.get("toLinks")
                } yield s"Transaction GET LINKS BY TABLE from $dbName completed with ${from.length} fromLinks and ${to.length} toLinks!")
                .getOrElse(s"Transaction GET LINKS BY TABLE from $dbName completed!")
                .pipe(console.info(_))
              )
              .pipe(promise.success)
            )
            .pipe(call)
            .onComplete({
              case Success(res) => result.addAll(res)
              case Failure(exception) =>
                console.error(s"Failed to get links by node  from $dbName: ${exception.getMessage}")
                transaction.abort()
            })

            promise.future
          })
  }

  def expandNode(optTx: Option[IDBTransaction] = None)(node: go.ObjectData, expand: Boolean): Future[js.Array[LineageNode]] = {
    def call(transaction: IDBTransaction): Future[scala.scalajs.js.Array[LineageNode]] =
        transaction.objectStore(GoJSStoreName.nodes)
          .pipe(store =>
            node.asInstanceOf[LineageNode]
              .setExpanded(expand)
              .pipe((selected: LineageNode) =>
                for {
                  key <- updateRequest(selected)(store)

                  children <- selected.isGroup match
                    case false => Future.successful( js.Array[IDBValue]() )
                    case true =>
                      getWithFilterRequest(
                        optIdxName = Some("groupIDX"),
                        optRange = Some(IDBKeyRange.only(selected.key))
                      )(store)
                      .map(
                        _.asInstanceOf[js.Array[LineageNode]]
                        .map(_.setVisible(expand))
                      )

                  _ <- Future.sequence(
                      children.map(child => updateRequest(child.asInstanceOf[LineageNode])(store))
                    )
                } yield (children)
              )
          )
          .map(_.asInstanceOf[js.Array[LineageNode]])

    optTx match
      case Some(transaction) =>
        call(transaction)
      case None =>
        openDBRequest()
          .map(_.transaction(GoJSStoreName.nodes, IDBTransactionMode.readwrite))
          .flatMap((transaction: IDBTransaction) =>
            val promise = Promise[js.Array[LineageNode]]()
            val result: js.Array[LineageNode] = js.Array[LineageNode]()

            transaction
              .tap(_.onerror = (event: ErrorEvent) =>
                event
                  .tap(logEvent(console.error))
                  .pipe(getErrorMsg(_))
                  .pipe(msg => s"Transaction EXPAND NODE failed with message: $msg")
                  .tap(console.error(_))
              )
              .tap(_.onabort = (event: Event) =>
                event
                  .tap(logEvent(console.warn))
                  .tap(_ => console.warn(s"Transaction EXPAND NODE from $dbName aborted!"))
                  .pipe(e => promise.failure(new Exception(s"Transaction EXPAND NODE aborted! ${e.toString()}")))
              )
              .tap(_.oncomplete = (event: Event) =>
                result
                .tap(
                  _.pipe(n => s"Transaction EXPAND NODE from $dbName completed!")
                  .pipe(console.info(_))
                )
                .pipe(promise.success)
              )
              .pipe(call)
              .onComplete({
                case Success(children: js.Array[LineageNode]) => children
                  .tap(key => expand match
                    case true => console.info(s"Expanded node with key ${node.asInstanceOf[LineageNode].key}.")
                    case false => console.info(s"Collapsed node with key ${node.asInstanceOf[LineageNode].key}.")
                  )
                  .pipe(_.foreach(result.addOne))
                case Failure(exception) =>
                  (expand match
                    case true => "expand"
                    case false => "collapse"
                  )
                  .pipe(eOrc => s"Failed to $eOrc node with key ${node.asInstanceOf[LineageNode].key} : ${exception.getMessage}")
                  .tap(console.error(_))
                  .pipe(_ => transaction.abort())
              })

              promise.future
          )
  }

  def getNodeByKey(optTx: Option[IDBTransaction] = None)(key: String): Future[go.ObjectData] = {
    def call(transaction: IDBTransaction): Future[IDBValue] =
          transaction.objectStore(GoJSStoreName.nodes)
            .pipe(getByPrimaryKeyRequest(key))

    optTx match
      case Some(transacion) =>
        call(transacion)
        .map(_.asInstanceOf[go.ObjectData])
      case None =>
        openDBRequest()
          .map(_.transaction(GoJSStoreName.nodes, IDBTransactionMode.readonly))
          .flatMap(transaction => {
            val promise = Promise[go.ObjectData]()
            val result: js.Array[go.ObjectData] = js.Array[go.ObjectData]()

            transaction
              .tap(_.onerror = (event: ErrorEvent) =>
                event
                  .tap(logEvent(console.error))
                  .pipe(getErrorMsg(_))
                  .pipe(msg => s"Transaction GET NODE failed with message: $msg")
                  .tap(console.error(_))
              )
              .tap(_.oncomplete = (event: Event) =>
                result(0)
                .tap(
                  _.pipe(n => s"Transaction GET NODE from $dbName completed!")
                  .pipe(console.info(_))
                )
                .pipe(promise.success)
              )
              .tap(_.onabort = (event: Event) =>
                event
                  .tap(logEvent(console.warn))
                  .tap(_ => console.warn(s"Transaction GET NODE from $dbName aborted!"))
                  .pipe(e => promise.failure(new Exception(s"Transaction GET NODE aborted! ${e.toString()}")))
              )
              .pipe(call)
              .onComplete({
                case Success(value: IDBValue) =>
                  console.info(s"Get node with key $key from $dbName.")
                  result.addOne(value.asInstanceOf[go.ObjectData])
                case Failure(exception) =>
                  console.error(s"Failed to get node with key $key from $dbName: ${exception.getMessage}")
                  transaction.abort()
              })

            promise.future
          })
  }

  def addLineage(optTx: Option[IDBTransaction] = None)(nodes: js.Array[go.ObjectData], links: js.Array[go.ObjectData], ignoreConstraintError: Boolean = false): Future[Boolean] = {
    def call(transaction: IDBTransaction): Future[js.Map[String, Int]] =
      Future.sequence(List(
        transaction
          .objectStore(GoJSStoreName.nodes)
          .pipe(clearStoreRequest)
          .flatMap(addBatchRequest(nodes, ignoreConstraintError))
          .map("nodes" -> _),
        transaction
          .objectStore(GoJSStoreName.links)
          .pipe(clearStoreRequest)
          .flatMap(addBatchRequest(links, ignoreConstraintError))
          .map("links" -> _)
      ))
      .map(js.Map(_: _*) )

    optTx match
      case Some(transaction) =>
        call(transaction)
        .as[Boolean](true)
      case None =>
        openDBRequest()
          .map(_.transaction(js.Array(GoJSStoreName.nodes, GoJSStoreName.links), IDBTransactionMode.readwrite))
          .flatMap((transaction: IDBTransaction) => {
            val promise = Promise[Boolean]()

            transaction
            .tap(_.onerror = (event: ErrorEvent) =>
              event
                .tap(logEvent(console.error))
                .pipe(getErrorMsg(_))
                .pipe(msg => s"Transaction ADD LINEAGE failed with message: $msg")
                .tap(console.error(_))
            )
            .tap(_.oncomplete = (event: Event) =>
              console.info(s"Transaction ADD LINEAGE into $dbName completed!")
                .pipe(_ => promise.success(true))
            )
            .tap(_.onabort = (event: Event) =>
              event
                .tap(logEvent(console.warn))
                .tap(_ => console.warn(s"Transaction ADD LINEAGE into $dbName aborted!"))
                .pipe(e => promise.failure(new Exception(s"Transaction ADD BATCH aborted! ${e.toString()}")))
            )
            .pipe(call)
            .onComplete({
              case Success(result) =>
                (for {
                  nodesCnt <- result.get("nodes")
                  linksCnt <- result.get("links")
                } yield nodesCnt -> linksCnt)
                .map((nodesCnt, linksCnt) =>
                  console.info(s"Added $nodesCnt nodes from ${nodes.length} into $dbName.")
                  console.info(s"Added $linksCnt links from ${links.length} into $dbName.")
                )
              case Failure(exception) =>
                console.error(s"Failed to add lineage into $dbName: ${exception.getMessage}")
                transaction.abort()
            })

            promise.future
          })
  }

  def getLineage(optTx: Option[IDBTransaction] = None): Future[js.Map[String, js.Array[go.ObjectData]]] = {
    def call(transaction: IDBTransaction): Future[js.Map[String, js.Array[go.ObjectData]]] =
      for {
        no <-
          transaction.objectStore(GoJSStoreName.nodes)
          .pipe(getWithFilterRequest(
            filter = Some(_.asInstanceOf[LineageNode].visible)
          ))

        li <-
          getLinksRequest()(transaction.objectStore(GoJSStoreName.links), transaction.objectStore(GoJSStoreName.nodes))

      } yield js.Map(
        "nodes" -> no.asInstanceOf[js.Array[go.ObjectData]],
        "links" -> li.asInstanceOf[js.Array[go.ObjectData]]
      )

    optTx match
      case Some(transaction) =>
        call(transaction)
      case None =>
        openDBRequest()
          .map(_.transaction(js.Array(GoJSStoreName.nodes, GoJSStoreName.links), IDBTransactionMode.readwrite))
          .flatMap((transaction: IDBTransaction) => {
            val promise = Promise[js.Map[String, js.Array[go.ObjectData]]]()
            val result: js.Map[String, js.Array[go.ObjectData]] = js.Map.empty[String, js.Array[go.ObjectData]]

            transaction
            .tap(_.onerror = (event: ErrorEvent) =>
              event
                .tap(logEvent(console.error))
                .pipe(getErrorMsg(_))
                .pipe(msg => s"Transaction GET LINEAGE failed with message: $msg")
                .tap(console.error(_))
            )
            .tap(_.onabort = (event: Event) =>
              event
                .tap(logEvent(console.warn))
                .pipe(e => s"Transaction GET LINEAGE from $dbName aborted! ${e.toString()}")
                .tap(_ => console.warn(_))
                .pipe(new Exception(_))
                .pipe(promise.failure)
            )
            .tap(_.oncomplete = (event: Event) =>
              result
              .tap(lineage =>
                (for {
                  linksCnt <- lineage.get("links").map(_.length)
                  nodesCnt <- lineage.get("nodes").map(_.length)
                } yield nodesCnt -> linksCnt)
                .getOrElse(0 -> 0)
                .pipe{case (nodesCnt, linksCnt) => s"Transaction GET LINEAGE from $dbName completed with $nodesCnt nodes and  $linksCnt links!"}
                .tap(console.info(_))
              )
              .pipe(promise.success)
            )
            .pipe(call)
            .onComplete({
              case Success(res) => result.addAll(res)
              case Failure(exception) =>
                console.error(s"Failed to get lineage from $dbName: ${exception.getMessage}")
                transaction.abort()
            })

            promise.future
          })
  }

  def getLinks(optTx: Option[IDBTransaction] = None): Future[js.Array[LineageLink]] = {
    def call(transaction: IDBTransaction): Future[js.Array[LineageLink]] =
      getLinksRequest()(transaction.objectStore(GoJSStoreName.links), transaction.objectStore(GoJSStoreName.nodes))

    optTx match
      case Some(transaction) => call(transaction)
      case None =>
        openDBRequest()
          .map(_.transaction(js.Array(GoJSStoreName.nodes, GoJSStoreName.links), IDBTransactionMode.readonly))
          .flatMap((transaction: IDBTransaction) => {
            val promise = Promise[js.Array[LineageLink]]()
            val result: js.Array[LineageLink] = js.Array[LineageLink]()

            transaction
            .tap(_.onerror = (event: ErrorEvent) =>
              event
                .tap(logEvent(console.error))
                .pipe(getErrorMsg(_))
                .pipe(msg => s"Transaction GET LINKS failed with message: $msg")
                .tap(console.error(_))
            )
            .tap(_.onabort = (event: Event) =>
              event
                .tap(logEvent(console.warn))
                .pipe(e => s"Transaction GET LINKS from $dbName aborted! ${e.toString()}")
                .tap(_ => console.warn(_))
                .pipe(new Exception(_))
                .pipe(promise.failure)
            )
            .tap(_.oncomplete = (event: Event) =>
              result
              .tap(links => console.info(s"Transaction GET LINKS from $dbName completed with ${links.length} links!"))
              .pipe(promise.success)
            )
            .pipe(call)
            .onComplete({
              case Success(links) => result.addAll(links)
              case Failure(exception) =>
                console.error(s"Failed to get links from $dbName: ${exception.getMessage}")
                transaction.abort()
            })

            promise.future
          })
  }
}
